import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-821458de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "number-change" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{ 'movedown': _ctx.move }, "hidden number"])
    }, _toDisplayString(_ctx.useNum - 1 < 0? 9 : _ctx.useNum - 1), 3),
    _createElementVNode("div", {
      class: _normalizeClass([{'movedown': _ctx.move}, "number"])
    }, _toDisplayString(_ctx.useNum > _ctx.over?0: _ctx.useNum), 3)
  ]))
}