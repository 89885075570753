

import {computed, defineComponent, onBeforeUnmount, onMounted, Ref, ref, toRaw, toRefs, watch} from "vue";
import {LeftCircleFilled, RightCircleFilled,DownOutlined,RedoOutlined} from '@ant-design/icons-vue'
import {coursewareBill} from "@/views/timetable/myTimetable/Courseware/Courseware";
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";
import SwiperCore, {Keyboard, EffectFade, Navigation, Controller,} from 'swiper';
import {Image, Modal, Button} from "ant-design-vue";
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import BackgroundImgChange from "@/components/timetable/BackgroundImgChange.vue";
import InteractionMenu from "@/components/timetable/InteractionMenu.vue";
import {useStore} from "vuex";
import TimetableProgress from "@/components/timetable/TimetableProgress.vue";
import Choujiang from "@/components/timetable/Choujiang.vue";
import Jishiqi from "../../../../components/timetable/Jishiqi.vue";
import Jiangli from "../../../../components/timetable/Jiangli.vue";
import Xiang from "../../../../components/timetable/Xiang.vue";
import Base64 from '@/utils/base64.js'

SwiperCore.use([Keyboard, EffectFade, Navigation, Controller]);


export default defineComponent({
  name: "Courseware",
  components: {
    Xiang,
    Jiangli,
    Jishiqi,
    Choujiang,
    TimetableProgress,
    InteractionMenu,
    BackgroundImgChange,
    LeftCircleFilled,
    RightCircleFilled,
    // ACarousel: Carousel,
    Swiper,
    SwiperSlide,
    AImage: Image,
    AImagePreviewGroup: Image.PreviewGroup,
    AModal: Modal,
    DownOutlined,
    AButton:Button,
    RedoOutlined,
  },
  setup(props, context) {
    const {
      onOutCourseware,
      onSwiperUpdate,
      onScrollChange,
      onSwiperNextStart,
      onMenuShowChange,
      onReward,
      setMenuShow,
      refData,
      swiperRefDomS,
      init,
      swiperRefDom,
      onJiangli,
      omPing,
      pageInt,
      close,
      weixiaomo,
    } = coursewareBill();


    onMounted(async () => {
      await init();
      await store.dispatch('actStarCountTimeState', 'start')
    })

    onBeforeUnmount(async () => {
      // 去其他页面前暂停计时
      await store.dispatch('actStarCountTimeState', 'suspend')
    })

    const store = useStore();
    let controlledSwiper: any = ref(null);
    let swiperSlideRefDom: any = ref(null);
    let getWatermark = computed(() => {
      let info = JSON.parse(localStorage.getItem('user') as string)
      let base64Name = new Base64().strToBase64('      ' + info.name + info.mobile.toString().substring(7) + '      ')
      return '?x-oss-process=image/watermark,type_d3F5LXplbmhlaQ,text_' + base64Name + ',size_40,rotate_340,fill_1,color_f5e6e3,shadow_0,t_25'
    })

    // const showMenu = computed(() => store.getters.getCoursewareShowMenuState)
    const fullscreenState = computed(() => store.getters.getCoursewareFullscreenState);
    const coursewareState = computed(() => store.getters.getCoursewareState);
    const coursewareIndex = computed(() => store.getters.getCoursewareIndex);

    const onSwiperChange = (num: number) => {
      swiperSlideRefDom.value.swiperRef.value.activeIndex = num;
      controlledSwiper.value = swiperSlideRefDom.value.swiperRef;
    }

    const slideTo = (index, speed) => {
      console.log(index);
    }
    let box: any = ref(null)
    const imgRef = (el: HTMLElement) => {
      box = el;
    }
    // const mouseover = (Box:any)=>{
    //   box.style.opacity = 0
    // }

    let swiperdom: any = ref(null)

    const setSwiper = (swiper: any) => {
      controlledSwiper.value = swiper;
    }

    watch(coursewareIndex, value => {
      if (swiperRefDomS.value[value].nodeName.toLocaleLowerCase() === 'video') {
        swiperRefDomS.value[value].play();
      }
      if (value > 0 && swiperRefDomS.value[value - 1].nodeName.toLocaleLowerCase() === 'video') {
        swiperRefDomS.value[value - 1].pause();
      }
      if (swiperRefDomS.value[value + 1] && swiperRefDomS.value[value + 1].nodeName.toLocaleLowerCase() === 'video') {
        swiperRefDomS.value[value + 1].pause();
      }
    })

    return {
      ...refData,
      onOutCourseware,
      onScrollChange,
      onSwiperUpdate,
      onSwiperNextStart,
      onMenuShowChange,
      onReward,
      swiperRefDom,
      swiperSlideRefDom,
      onJiangli,
      omPing,
      // showMenu,
      fullscreenState,
      coursewareState,
      coursewareIndex,
      onSwiperChange,
      swiperdom,
      slideTo,
      controlledSwiper,
      setSwiper,
      getWatermark,
      box,
      // mouseover,
      imgRef,
      pageInt,
      close,
      weixiaomo,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },

  },
  watch: {}
})
