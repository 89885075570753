
import {Modal} from 'ant-design-vue';
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import {CloseCircleOutlined, SyncOutlined} from '@ant-design/icons-vue'
import DwAvatar from "@/components/system/DwAvatar.vue";
import service from "@/utils/axios";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "Choujiang",
  props: {
    visible: {
      type: Boolean,
    },
    rules: {
      type: Array,
      required: true,
    }
  },
  components: {
    DwAvatar,
    AModal: Modal,
    CloseCircleOutlined,
    SyncOutlined
  },
  data() {
    return {}
  },
  setup(props, context){
    let choujiangVisible = ref(false);
    let choujiangRef = ref(null);

    const route = useRoute();
    let stateRef = reactive({
      loading: false,
      choujiangStu: <any>{
        avatar: null,
        studentId: '',
        studentName:'',
        listModels:[],
      },
    })

    let refData = toRefs(stateRef)

    let avatar = ref('');
    let name = ref('');
    let canSend = ref(false);
    let timeout;

    let onReward = (item: any) => {
      if (canSend.value){
        context.emit('jiangli', item, stateRef.choujiangStu);
      }
    }

    const getChouOrShare = async (type?: string) => {
      /**
       * 抽奖或分享
       * extractType    1: 抽奖  2: 分享
       */
      if (type ==='re' && !canSend.value){
        return;
      }
      const r:any = await service.post('/dwart/an_teaching/classCourse/extractStudents', {
        classId: route.query.classId,
        classScheduleId: route.query.scheduleId,
        scheduleIndexStr: route.query.courseIndex,
        extractType: 1,
      })
      stateRef.choujiangStu = r;
      await setAvatar()
    }
    const setAvatar = async () => {
      canSend.value = false
      timeout = setInterval(()=>{
        let randomint = parseInt((Math.random()* stateRef.choujiangStu.listModels.length).toString(), 10)
        avatar.value = stateRef.choujiangStu.listModels[randomint]?.avatar;
        name.value = stateRef.choujiangStu.listModels[randomint]?.studentName;
      }, 200)
      setTimeout(()=>{
        clearInterval(timeout)
        avatar.value = stateRef.choujiangStu.avatar;
        name.value = stateRef.choujiangStu.studentName;
        canSend.value = true;
        let audio = document.createElement('audio')
        audio.src = require('@/assets/audio/奖励.wav');
        audio.play();
      }, 3100)
    }


    watch(()=>props.visible, v=>{
      choujiangVisible.value = v;
      if (v){
        getChouOrShare();
      }
    })
    watch(()=>choujiangVisible.value, (v)=>{
      if (!v){
        clearInterval(timeout)
      }
      context.emit('update:visible', v);
    })


    return {
      ...refData,
      choujiangVisible,
      choujiangRef,
      onReward,
      getChouOrShare,
      avatar,
      name,
      canSend,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
