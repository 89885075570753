import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/image/timetable/ic_shou.png'


const _withScopeId = n => (_pushScopeId("data-v-09674029"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "interaction-menu" }
const _hoisted_2 = { class: "interaction-menu__trans" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { style: {"font-size":"18px","font-weight":"bold"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "interaction-menu__icon",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMenuColChange && _ctx.onMenuColChange(...args)))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "",
          class: _normalizeClass(_ctx.menuShowRef?'interaction-menu__imgcos':'interaction-menu__imgcol')
        }, null, 2)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.menuShowRef?'interaction-menu__cos':'interaction-menu__coll'),
        style: {"transition":"all .5s"}
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (i, k) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: k,
            onClick: ($event: any) => ((i.func)()),
            class: "interaction-menu__cositem"
          }, [
            _createElementVNode("img", {
              src: i.img,
              alt: ""
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, _toDisplayString(i.name), 1)
          ], 8, _hoisted_3)), [
            [_vShow, !i.hidden]
          ])
        }), 128))
      ], 2)
    ])
  ]))
}